.titleNeon {
    text-align: center;
    font-size: 6em;
    margin: 20px 0 10px 0;
    text-decoration: none;
    font-weight: 500;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    color: #fff;
    -webkit-animation: neon1 1.5s ease-in-out infinite alternate;
    -moz-animation: neon1 1.5s ease-in-out infinite alternate;
    animation: neon1 1.5s ease-in-out infinite alternate;

 }

 .buttonFlex {
   display: flex;
   justify-content: center;
 }


@-webkit-keyframes neon1 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
    }
  }

  @-moz-keyframes neon1 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
    }
  }

  @keyframes neon1 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FF1177, 0 0 70px #FF1177, 0 0 80px #FF1177, 0 0 100px #FF1177, 0 0 150px #FF1177;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FF1177, 0 0 35px #FF1177, 0 0 40px #FF1177, 0 0 50px #FF1177, 0 0 75px #FF1177;
    }
  }

  .rankTitle {
    color: #9c27b0;
    text-align: center;
    font-weight: 700;
    /* -webkit-animation: neon3 1.5s ease-in-out infinite alternate;
    -moz-animation: neon3 1.5s ease-in-out infinite alternate;
    animation: neon3 1.5s ease-in-out infinite alternate; */
  }

  @-webkit-keyframes neon3 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #9c27b0, 0 0 70px #9c27b0, 0 0 80px #9c27b0, 0 0 100px #9c27b0, 0 0 150px #9c27b0;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #9c27b0, 0 0 35px #9c27b0, 0 0 40px #9c27b0, 0 0 50px #9c27b0, 0 0 75px #9c27b0;
    }
  }

  @-moz-keyframes neon3 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #9c27b0, 0 0 70px #9c27b0, 0 0 80px #9c27b0, 0 0 100px #9c27b0, 0 0 150px #9c27b0;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #9c27b0, 0 0 35px #9c27b0, 0 0 40px #9c27b0, 0 0 50px #9c27b0, 0 0 75px #9c27b0;
    }
  }

  @keyframes neon3 {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #9c27b0, 0 0 70px #9c27b0, 0 0 80px #9c27b0, 0 0 100px #9c27b0, 0 0 150px #9c27b0;
    }
    to {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #9c27b0, 0 0 35px #9c27b0, 0 0 40px #9c27b0, 0 0 50px #9c27b0, 0 0 75px #9c27b0;
    }
  }

