.rankContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.rankingP {
    font-size: 28px;
    padding: 15px;
}
.rankButton {
    z-index: 999;
    flex: 1 1 15%;

}
.rankImgName {
    display: flex;
}
.rankImg {
    width: 100px;
    margin: 25px;
    box-sizing: border-box;
    box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.rankSocial {
    flex: 0 0 5%;
}    
.buttonCount {
    display: contents;
    position: absolute;
}

button > * {
    pointer-events: none;
}

@media screen and (max-width: 1200px) {
    .rankingP {
        flex: 1 1 45%;
    }
    .rankButton {
        flex: 1 1 33%;
    }
  }

  @media screen and (max-width: 775px) {
    .rankImg {
        width: 80%;
        margin: auto;
    }
    .rankButton {
        align-self: stretch;
    }
    .rankContainer {
        flex-direction: column;
    }
  }